import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
  SIDE_NAV_DARK,
  NAV_TYPE_TOP
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "Dattas";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";

const localStorageDate =
  JSON.parse(localStorage.getItem("dattas.bookingDate")) || null;
console.log("localStorageDate", localStorageDate);

var date = new Date();
var _month = localStorageDate ? localStorageDate.month : date.getMonth() + 1;
var _year = localStorageDate ? localStorageDate.year : date.getFullYear();

export const THEME_CONFIG = {
  navCollapsed: true,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_TOP,
  topNavColor: "rgba(255,255,255,0)",
  headerNavColor: "#ffffff",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
  sel_casting: null,
  viewMode: "LIST",
  monthYear: { month: _month, year: _year }
};
