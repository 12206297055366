import antdEnUS from 'antd/es/locale/pt_BR';
import enMsg from "../locales/pt_BR.json";

const PtLang = {
  antd: antdEnUS,
  locale: 'pt-BR',
  messages: {
    ...enMsg
  },
};
export default PtLang;
