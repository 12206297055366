import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import { 
  EditOutlined, 
  SettingOutlined,
  UserOutlined,
  ShopOutlined, 
  QuestionCircleOutlined, 
  LogoutOutlined 
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';

const menuItem = [
	{
		title: "Editar Perfil",
		icon: EditOutlined ,
		path: "/app/management/preferences/edit-profile",
  },
  {
		title: "Configurações",
		icon: SettingOutlined,
		path: "/app/management/preferences/manager",
  },
  {
		title: "Licença",
		icon: ShopOutlined ,
		path: "/",
    disabled: true
	},
  {
		title: "Central de Ajuda",
		icon: QuestionCircleOutlined,
		path: "/app/more/faq",
	}
]

export const NavProfile = ({signOut}) => {
  const profileImg = "/img/avatars/thumb-1.jpg";
  const data_logged_user = JSON.parse(window.localStorage.getItem('auth_data'))
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={data_logged_user?.foto ? data_logged_user?.foto : profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{data_logged_user?.nome}</h4>
            <span className="text-muted">{data_logged_user?.producer?.nome}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item disabled={el.disabled} key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">Deslogar</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex text-center align-item-center" mode="horizontal">
        <Menu.Item key="profile" className="m-0 p-0">
          <Avatar shape="square" style={{ backgroundColor: 'transparent', border: '1px solid #E4E6EF', marginRight: '.5em' }}
            icon={<UserOutlined style={{ color: '#333' }} />} className="text-center" />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, {signOut})(NavProfile)
