import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Select, Image } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined } from '@ant-design/icons';
import Logo from './Logo';
import NavNotification from './NavNotification';
import NavProfile from './NavProfile';
import NavLanguage from './NavLanguage';
import NavPanel from './NavPanel';
import NavSearch  from './NavSearch';
import SearchInput from './NavSearch/SearchInput.js'
import { toggleCollapsedNav, onMobileNavToggle, onCastingChange } from 'redux/actions/Theme';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import utils from 'utils'
import MenuContent from "./MenuContent";
import fetch from '../../auth/FetchInterceptor'

const { Header } = Layout;
const { Option } = Select;

export const HeaderNav = props => {

  const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme, sel_casting, onCastingChange } = props;
  const [searchActive, setSearchActive] = useState(false)
	const [listCasting, setListCasting] = useState([])

  const onSearchActive = () => {
    setSearchActive(true)
  }

  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if(!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = ()=> {
    if(!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff' )
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if(isNavTop || isMobile) {
      return '0px'
    }
    if(navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }

  useEffect(() => {
    if(listCasting.length<=0){
      getCasting()
    }
    if(!isMobile) {
      onSearchClose()
    }
  },[])

  const getCasting = async () => {
		const user_logged_data = JSON.parse(window.localStorage.getItem('auth_data'))
		if(user_logged_data){
			await fetch({
				url: '/get_casting',
				method: 'POST',
				data: {
					id_user: user_logged_data?.id,
					token: window.localStorage.getItem('auth_token')
				}
			}).then((data)=>{
				//console.log('getCasting', data)
				if(data.status){
					setListCasting(data?.data_casting)
          if(!sel_casting){
            onCastingChange(data?.data_casting[0])
          }
				}
			})
		}
	}

  const updateCasting = (e) => {
    const arr_cast_selected = listCasting.length >= 1 && listCasting.filter((cast) => cast.id == e)
    if(arr_cast_selected)
    onCastingChange(arr_cast_selected[0])
  }
  
  return (
    <Header className={`app-header ${navMode}`} style={{backgroundColor: headerNavColor}}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode}/>
        <div className="nav" style={{width: `calc(100% - ${getNavWidth()})`}}>
          <div className="nav-left">
            <ul className="ant-menu menu_logo_mobile ant-menu-root ant-menu-horizontal">          
              {
                isNavTop && !isMobile ?
                null
                :
                <li className="dattas-logo ant-menu-item ant-menu-item-only-child mx-0 ml-2" onClick={() => {/*onToggle()*/}}>
                  <Image preview={false} src="/img/icon.png" className="d-flex" />
                  {/*navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />*/}
                </li>
              }
              <li className="ant-menu-item ant-menu-item-only-child no-hover select_casting">
                <Select value={sel_casting ? parseInt(sel_casting?.id) : null} placeholder="Visão Geral" style={{ minWidth: '200px', textAlign: 'left' }} onChange={(e)=> updateCasting(e)}>
                  <Option value={0}>Visão Geral</Option>
                  {listCasting.length >= 1 && listCasting.sort(function(a,b){
                    return (a.position === null ? Infinity : a.position) - (b.position === null ? Infinity : b.position);
                  }).map((cast) => <Option value={parseInt(cast?.id)} key={cast?.id}><span style={{ color: `#${cast?.cor}`, fontSize: '1em' }}>&bull;</span> {cast?.nome}</Option>)}
                </Select>
              </li>
              {
                /*isMobile ?
                <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {onSearchActive()}}>
                  <SearchOutlined />
                </li>
                :
                <li className="ant-menu-item ant-menu-item-only-child" style={{cursor: 'auto'}}>
                  <SearchInput mode={mode} isMobile={isMobile} />
                </li>*/
              }
            </ul>
          </div>
          {
            !isMobile &&
            <div className="menuright">
              <MenuContent 
                type={NAV_TYPE_TOP}
                {...props}
              />
            </div>
          }
          <div className="nav-right">
            {!isMobile && <>
              <NavProfile />
            </>}
            <NavNotification />
            {/*<NavLanguage />
            <NavPanel direction={direction} />
            <NavSearch active={searchActive} close={onSearchClose}/>*/}
          </div>
        </div>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction, sel_casting } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction, sel_casting }
};

export default connect(mapStateToProps, {toggleCollapsedNav, onMobileNavToggle, onCastingChange})(HeaderNav);