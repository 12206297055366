import { 
  DashboardOutlined, 
  AppstoreOutlined,
  FileTextOutlined,
  PieChartOutlined,
  EnvironmentOutlined,
  AntDesignOutlined,
  SafetyOutlined,
  StopOutlined,
  DotChartOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BulbOutlined,
  InfoCircleOutlined,
  CompassOutlined,
  LayoutOutlined,
  DesktopOutlined,
  FileDoneOutlined,
  CommentOutlined,
  RobotOutlined,
  PlusCircleOutlined,
  FundOutlined,
  ShoppingCartOutlined,
  BookOutlined,
  FileUnknownOutlined,
  ProfileOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards/default`,
  title: 'Dashboard',
  //icon: DashboardOutlined,
  breadcrumb: false,
  submenu: []
}]

const appsNavTree = [{
  key: 'booking',
  path: `${APP_PREFIX_PATH}/book/booking`,
  title: 'Agenda',
  //icon: CalendarOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'apps-booking-calendario',
      path: `${APP_PREFIX_PATH}/book/booking`,
      title: 'Booking',
      icon: '',
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'apps-booking-sugestao',
      path: `${APP_PREFIX_PATH}/book/sugestao`,
      title: 'Sugestão de Vendas',
      icon: '',
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'apps-booking-anual',
      path: `${APP_PREFIX_PATH}/book/anual`,
      title: 'Calendário Anual',
      icon: '',
      breadcrumb: false,
      submenu: []
    }
  ],
  /*submenu: [
    {
      key: 'apps-booking',
      path: `${APP_PREFIX_PATH}/book`,
      title: 'Book',
      icon: CalendarOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'apps-booking-calendario',
          path: `${APP_PREFIX_PATH}/book/booking`,
          title: 'Calendário',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'apps-booking-sugestao',
          path: `${APP_PREFIX_PATH}/book/sugestao`,
          title: 'Sugestão de Vendas',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'apps-booking-contratantes',
          path: `${APP_PREFIX_PATH}/book/contratantes`,
          title: 'Contratantes',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'apps-booking-eventos',
          path: `${APP_PREFIX_PATH}/book/eventos`,
          title: 'Eventos',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'apps-booking-locais',
          path: `${APP_PREFIX_PATH}/book/locais`,
          title: 'Locais',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'apps-booking-anual',
          path: `${APP_PREFIX_PATH}/book/anual`,
          title: 'Calendário Anual',
          icon: '',
          breadcrumb: false,
          submenu: []
        }
      ]
    },
    {
      key: 'apps-ecommerce',
      path: `${APP_PREFIX_PATH}/apps/ecommerce`,
      title: 'Casting de Artistas',
      icon: ShoppingCartOutlined,
      breadcrumb: true,
      submenu: []
    }
  ]*/
}]

const componentsNavTree = [
  {
    key: 'cadastros',
    path: `${APP_PREFIX_PATH}/cadastros`,
    title: 'Cadastros',
    //icon: AntDesignOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'apps-cadastros-contratantes',
        path: `${APP_PREFIX_PATH}/book/contratantes`,
        title: 'Contratantes',
        icon: '',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'apps-cadastros-vendedores',
        path: `${APP_PREFIX_PATH}/book/vendedores`,
        title: 'Intermediários',
        icon: '',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'apps-cadastros-eventos',
        path: `${APP_PREFIX_PATH}/book/eventos`,
        title: 'Eventos',
        icon: '',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'apps-cadastros-locais',
        path: `${APP_PREFIX_PATH}/book/locais`,
        title: 'Locais',
        icon: '',
        breadcrumb: false,
        submenu: []
      },
    ]
  }
]

const docsNavTree = [{
  key: 'financeiro',
  path: `${APP_PREFIX_PATH}/financial`,
  title: 'Financeiro',
  //icon: BookOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'financial-all-launches',
      path: `${APP_PREFIX_PATH}/financial/all-launches`,
      title: 'Todos os Lançamentos',
      //icon: FileUnknownOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'financial-outcome',
      path: `${APP_PREFIX_PATH}/financial/outcome`,
      title: 'Contas a Pagar',
      //icon: ProfileOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'financial-income',
      path: `${APP_PREFIX_PATH}/financial/income`,
      title: 'Contas a Receber',
      //icon: ProfileOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const extraNavTree = [
  {
    key: 'gestao',
    path: `${APP_PREFIX_PATH}/management`,
    title: 'Gestão',
    //icon: PlusCircleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'management-preferences',
        path: `${APP_PREFIX_PATH}/management/preferences`,
        title: 'Preferências',
        //icon: FileTextOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'management-users',
        path: `${APP_PREFIX_PATH}/management/users`,
        title: 'Usuários do Sistema',
        //icon: SafetyOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'management-documents',
        path: `${APP_PREFIX_PATH}/management/documents`,
        title: 'Documentos',
        //icon: SafetyOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'management-contratos',
        path: `${APP_PREFIX_PATH}/management/contracts`,
        title: 'Contratos',
        //icon: SafetyOutlined,
        breadcrumb: false,
        submenu: []
      },
    ]
  }
]

const moreNavTree = [{
  key: 'mais',
  path: `${APP_PREFIX_PATH}/more`,
  title: 'Mais',
  //icon: CalendarOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'apps-more-routes',
      path: `${APP_PREFIX_PATH}/more/routes`,
      title: 'Traçar Rota',
      icon: '',
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'apps-more-purposes',
      path: `${APP_PREFIX_PATH}/more/proposals`,
      title: 'Propostas',
      icon: '',
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'apps-more-faq',
      path: `${APP_PREFIX_PATH}/more/faq`,
      title: 'Central de Ajuda',
      icon: '',
      breadcrumb: false,
      submenu: []
    },
  ],
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...appsNavTree,
  ...componentsNavTree,
  ...docsNavTree,
  ...extraNavTree,
  ...moreNavTree
]

export default navigationConfig;
