import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Badge, Avatar, List, Button, Drawer } from 'antd';
import { 
  MailOutlined, 
  BellOutlined, 
  WarningOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import notificationData from "assets/data/notification.data.json";
import Flex from 'components/shared-components/Flex'
import fetch from '../../auth/FetchInterceptor'

const getIcon =  icon => {
  switch (icon) {
    case 'mail':
      return <MailOutlined />;
    case 'alert':
      return <WarningOutlined />;
    case 'check':
      return <CheckCircleOutlined />
    default:
      return <WarningOutlined />;
  }
}

const getNotificationBody = list => {
  return list.length > 0 ? <>
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={item => (
        <List.Item className="list-clickable">
          <Flex alignItems="center">
            <div className="pr-3">
              {item?.user?.foto ? <Avatar src={item?.user?.foto} /> : <Avatar className={`ant-avatar-${item.type} text-dark`} icon={getIcon(item.icon)} />}
            </div>
            <div className="mr-3 d-flex-col">
              <div className="font-weight-bold text-dark d-flex justify-space-between" style={{ fontSize: '13px' }}>
                {item?.user?.nome}
                <small className="ml-auto">há {item.time} dia{item.time>=2?`s`:``}</small>
              </div>
              <div className="text-gray-light" style={{ fontSize: '.85em', fontWeight: 500 }}>{item.desc}</div>
            </div>
          </Flex>
        </List.Item>
      )}
    />
  </> :
  <div className="empty-notification">
    <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
    <p className="mt-3">Nenhuma notificação pendente.</p>
  </div>;
}

export const NavNotification = () => {

  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([])

  useEffect(()=>{
    getNotifications()
  }, [])

	const getNotifications = async() => {
		const user_logged_data = JSON.parse(window.localStorage.getItem('auth_data'))
		if(user_logged_data){
			await fetch({
				url: '/get_notifications',
				method: 'POST',
				data: {
					id_user: user_logged_data?.id,
					token: window.localStorage.getItem('auth_token')
				}
			}).then((data)=>{
				console.log('getNotifications', data)
        if(data.status){
          setData(data?.data_notifications)
        }
			})
		}
	}

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notificações</h4>
        {/*<Button className="text-primary" type="link" onClick={() => setData([])} size="small">Limpar</Button>*/}
      </div>
      <div className="nav-notification-body">
        {getNotificationBody(data)}
      </div>
      {/*
        data.length > 0 ? 
        <div className="nav-notification-footer">
          <a className="d-block" href="#/">Ver Todas</a>
        </div>
        :
        null
      */}
    </div>
  );

  return (
    <Dropdown 
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification" className="m-0 p-0">
          <Badge count={data.length}>
            <Button className="px-3 count_notifications">
              {/*<BellOutlined className="nav-icon mx-auto" type="bell" />*/}
              {data.length}
            </Button>
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}


export default NavNotification;
