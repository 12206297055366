import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/book`} component={lazy(() => import(`./book`))} />
        <Route path={`${APP_PREFIX_PATH}/management`} component={lazy(() => import(`./management`))} />
        <Route path={`${APP_PREFIX_PATH}/more`} component={lazy(() => import(`./more`))} />
        <Route path={`${APP_PREFIX_PATH}/financial`} component={lazy(() => import(`./financial`))} />

        <Route path={`${APP_PREFIX_PATH}/report/:code`} component={lazy(() => import(`./report`))} />
        <Route path={`${APP_PREFIX_PATH}/reports`} component={lazy(() => import(`./soon`))} />
        <Route path={`${APP_PREFIX_PATH}/beneficios-fiscais`} component={lazy(() => import(`./soon`))} />
        <Route path={`${APP_PREFIX_PATH}/match`} component={lazy(() => import(`./soon`))} />
        <Route path={`${APP_PREFIX_PATH}/conciliacao-de-cartao`} component={lazy(() => import(`./soon`))} />

        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);