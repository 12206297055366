import axios from 'axios'
import { API_BASE_URL } from '../configs/AppConfig'

export const getModulesUser = () => {
    const user_logged_data = JSON.parse(window.localStorage.getItem('auth_data'))
    if(user_logged_data){
        return axios({
            url: `${API_BASE_URL}/get_user_permissions`,
            method: 'POST',
            data: {
                id_user: user_logged_data?.id,
                token: window.localStorage.getItem('auth_token')
            }
        })
        .then((data)=>{
            const datta = data.data
            //console.log('getModulesUser', datta)
            if(datta.status){
                return datta
            }
        })
    }else{
        return [{ can_read: [], can_manager: [] }]
    }
}